<template>
  <div class="dialog-content" v-loading="loading" element-loading-text="数据导出中!请耐心等待" style="height:250px">
  </div>
</template>

<script>
import { mixin } from '@/components/Dialog/components/mixin.js'
export default {
    name:'ExportFile',
    mixins: [mixin],
    data () {
        return {
            loading:true
        }
    },
    created () {
        this.getExportKey()
    },
    methods:{
        async getExportKey () {
            try {
              const res = await this.dialog.data.exportOption.getExportKeyApi(this.dialog.data.exportOption.getExportKeyApiData)
              const interval = setInterval( async () => {
                await this.queryExport(res.data, interval)
              }, 3000)
            } catch (err) {}
        },
        async queryExport (key,interval) {
            try {
              const { code,data } = await this.dialog.data.exportOption.queryExportApi(key)
              if (code == 200 && data) {
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = data.accessUrl
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                this.$emit('update:dialog', { ...this.dialog, hideTitle: false })
                this.loading = false
                clearInterval(interval)
                this.$message.success('导出成功')
                this.$emit('dialogEvent', 'dialogClose')
              }
            } catch (err) {
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>
