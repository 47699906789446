<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm
        v-model="form"
        ref="myForm"
        :options="formOpt"
        label-width="80px"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import { mixin } from '@/components/Dialog/components/mixin.js'
import { addMessageTemplate } from '@/api/vip/wxService/msgTemplate' //微信消息的模板
export default {
  name: 'ImportWXTemplate',
  mixins: [mixin],
  data () {
    return {
      requests: { add: addMessageTemplate },
      show: false,
      formOpt: []
    }
  },
  async created () {
    const formOpt = [
      this.$select({
        key: 'wx_app_type',
        getModel: true,
        option: {
          rules: [
            {
              required: true,
              message: '请选择消息类型',
              trigger: ['blur', 'change']
            }
          ]
        }
      }),
      {
        model: 'templateNo',
        type: 'input',
        label: '模板编号',
        rules: [
          {
            required: true,
            message: '请输入模板编号',
            trigger: ['blur', 'change']
          }
        ]
      },
      {
        model: 'templateRemark',
        type: 'input',
        label: '模板备注',
        rules: [
          {
            required: true,
            message: '请输入模板备注',
            trigger: ['blur', 'change']
          }
        ]
      }
    ]
    await this.format(formOpt)
    this.show = true
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
