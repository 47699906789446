<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { addStoreCheckBill, addShopCheckBill, addDecorationCheckBill, addFreshlyCheckBill } from "@/api/purchase/index";
import moment from "moment";
export default {
  name: "StoreBillCheck",
  mixins: [mixin],
  data() {
    return {
      requests: {},
      show: false,
      oldText: "",
      formOpt: [],
    };
  },
  async created() {
    const allAdd = {
      130101: addStoreCheckBill, // 仓库盘点
      140309: addShopCheckBill, // 门店盘点
      170309: addDecorationCheckBill, // 裱花盘点
      170409: addFreshlyCheckBill, // 现烤盘点
    };
    let parmasData = {
      storeTypes: [1, 2]
    }
    this.requests.add = allAdd[this.data.type];
    const formOpt = [
      {
        model: "checkName",
        type: "input",
        label: "任务名称",
        loadFormat: (v) => {
          let text = v
            ? v
            : `${moment().format("yyyy-MM-DD HH:mm:ss")} ${
                this.$store.state.user.userinfo.userName
              }创建的盘点单`;
          this.oldText = text;
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入任务名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      this.$select({
        key: this.data.type == '170409'? 'listFreshlyInventory' : this.data.type == '170309'? 'listDecorInventory' : "listInventory",
        getModel: true,
        option: {
          label: "盘点仓库",
          rules: [
            {
              required: true,
              message: "请选择盘点仓库",
              trigger: ["blur", "change"],
            },
          ],
          option: {
            remoteBody: this.data.type == '140309'? parmasData : {},
            change: (v, { storeName } = {}) => {
              if (this.oldText === this.form.checkName) {
                const text = `${moment().format("yyyy-MM-DD HH:mm:ss")} ${
                  this.$store.state.user.userinfo.userName
                }创建的${storeName ? `${storeName}的` : ""}盘点单`;
                this.form.billDate = moment().format("yyyy-MM-DD");
                this.form.checkName = text;
                this.oldText = text;
              }
            },
          },
        },
      }),
      this.$select({
        key: "listCheckEmployee",
        getModel: true,
      }),
      {
        label: "锁盘",
        model: "isLockStore",
        loadFormat: (v) => (v ? v : false),
        type: "checkbox-more",
        option: {
          data: [{ value: true, label: "是否锁盘" }],
        },
      },
      {
        label: "盘点范围",
        model: "isIncludeZeroStock",
        type: "checkbox-more",
        option: {
          data: [{ value: true, label: "是否包含零库存商品" }],
        },
      },
      {
        label: "商品来源",
        model: "checkGoodsSourceType",
        loadFormat: (v) => (v ? v : 1),
        type: "radio",
        optionData: [
          { value: 1, label: "在盘点仓库中有交易记录的商品" },
          { value: 2, label: "商品资料中所有商品" },
        ],
      },
      {
        label: "商品清单展示",
        model: "checkListShowType",
        type: "radio",
        loadFormat: (v) => (v ? v : 1),
        optionData: [
          { value: 1, label: "空白盘点单" },
          { value: 2, label: "显示商品清单" },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
