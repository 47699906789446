var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("TablePage", {
              model: {
                value: _vm.options,
                callback: function ($$v) {
                  _vm.options = $$v
                },
                expression: "options",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("dialogEvent", "dialogClose")
                  },
                },
              },
              [_vm._v("关 闭")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  disabled: _vm.options.check.length <= 0,
                  type: "primary",
                },
                on: { click: _vm.handleRestore },
              },
              [_vm._v("还 原")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }