<template>
  <div class="dialog-content">
    <div class="content">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">
        确 认
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  listDistributeAddBill,
  addDistributeObject
} from "@/api/produce/bill/autoDistribute"; //智能分货
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "AskGoodsAddOrders",
  components: { TablePage: () => import("@/components/tablePage/index.vue") },
  mixins: [mixin],
  data() {
    return {
      options: {
        hidePagination: true,
        mutiSelect: true,
        loading: false,
        // pagination: {
        //   total: 0,
        //   page: 1,
        //   size: 15,
        // },
        defaultBody: () => {
          return {
            goodsId: this.dialog.goodsInfo.goodsId,
            unitId: this.dialog.goodsInfo.unitId,
          }
        },
        getDataKey: (res) => {
          return {
            list: res.data
          }
        },
        getListApi: listDistributeAddBill,
        // listNo: true, // 序号
        height: '450px',
        check: [], // 选中数据
        rowKey: "billId",
        //表格
        columns: [
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "unitQty",
            label: "单据数量",
            minWidth: 120,
            align: "center",
          },
          // {
          //   prop: "basUnitQty",
          //   label: "基本单位数量",
          //   minWidth: 120,
          //   align: "center",
          // },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "arrivalDate",
            label: "到货日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderStoreName",
            label: "订货仓库名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "partnerName",
            label: "订货客户名称",
            minWidth: 120,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    handleEvent(...e) {
    },
    async submitForm(e) {
      if (this.options.check.length === 0) {
        return this.$message.error("请选择要添加的订单！");
      }
      let arr = []
      this.options.check.forEach(item => {
        arr.push({
          addBillId: item.billId,//加单的单据id
          addBillType: item.billType,//单据类型
          addGoodsId: this.dialog.goodsInfo.goodsId,//商品id
          addUnitId: this.dialog.goodsInfo.unitId,//单位id
          addUnitQty: item.unitQty,//基本单位数量
          addClientId: item.partnerId || item.orderStoreId,//订货对象id
          addToLineId: this.dialog.goodsInfo.lineId,//添加到哪一个产品入库单的id
        })
      })
      await addDistributeObject(arr)
      this.$emit('dialogEvent', 'dialogChange')
      this.$message.success("保存成功！");
      this.dialog.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>

