<template>
  <div class="dialog-content" v-if="dialog.data">
    <div class="content">
      <TablePage ref="tablePage" v-model="dialog.data" @handleEvent="handleEvent" />
    </div>
    <div slot="footer" class="dialog-footer" v-if="dialog.data.restoreListApi">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">关 闭</el-button>
      <el-button type="primary" @click="restore(dialog.click)">还 原</el-button>
    </div>
    <div slot="footer" class="dialog-footer" v-else>
      <el-button type="primary" @click="$emit('dialogEvent', 'dialogClose')">
        确 认
      </el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "Collection",
  components: { TablePage: () => import("@/components/tablePage/index.vue") },
  mixins: [mixin],
  data() {
    return {};
  },
  methods: {
    handleEvent(...e) {
    },
    async restore(e) {
      if (!e) {
        if (!this.dialog.data.check?.length) {
          return this.$message.warning("请选择要执行的数据");
        }
        try {
          await this.dialog.data.restoreListApi(
            this.dialog.data.check.map((x) => x[this.dialog.data.rowKey])
          ); // 还原接口 rowKey
          this.$message.success("还原成功");
          this.$emit("dialogEvent", "dialogChange");
        } catch (error) {}
      } else {
        this.$emit("dialogEvent", e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>
