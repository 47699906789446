<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="100px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
// import { getManList } from "@/api/freshlyBaked/base/roomMan";
import { getRoomList, getFreshlyRoomList } from "@/api/produce/decoration/index.js";
import { getAllTenantEmployeeByName } from "@/api/system/employee";
import { save, update } from "@/api/produce/decoration/index.js";
import { 
  save as freshlyBakedSave,
  update as freshlyBakeduUpdate,
} from "@/api/freshlyBaked/base/roomMan";

import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "RoomMan",
  mixins: [mixin],
  data() {
    return {
      requests: { add: save, update: update },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    console.log(this.data.type, 'type');
    if (this.data.type === 'freshlyBaked') {
      this.requests = { add: freshlyBakedSave, update: freshlyBakeduUpdate }
    } else {
      this.requests = { add: save, update: update }
    }
    console.log(this.data, 'data');
    const formOpt = [
      {
        model: "appManName",
        type: "local",
        label: "员工姓名",
        option: {
          disabled: this.dialog.click !== "add",
          remote: getAllTenantEmployeeByName,
          remoteBody: { shopName: "" },
          dataKey: "data",
          label: "employeeName",
          valueKey: "employeeId",
          filterable: true,
          labels: [
            { title: "员工编号", label: "employeeNo" },
            { title: "员工姓名", label: "employeeName" },
          ],
          change: (val) => {
            this.form.appManNo = val.employeeNo;
            this.form.appManName = val.employeeName;
            this.form.appManId = val.employeeId;
          },
        },
        rules: [
          {
            required: true,
            message: "请选择员工姓名",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "appManNo",
        type: "input",
        label: "员工编号",
        disabled: true,
      },
      {
        model: this.data.type == 'freshlyBaked'? 'freshlyBakedRoomIdStr' : "decorationRoomIdStr",
        type: "remote",
        label: this.data.type == 'freshlyBaked'? '所属现烤间' : "所属裱花间",
        rules: [
          {
            required: true,
            message: "请选择所属门店",
            trigger: ["blur", "change"],
          },
        ],
        loadFormat: (x, e) => this.data.type == 'freshlyBaked'? e.freshlyBakedRoomIdList : e.decorationRoomIdList, 
        option: {
          showItem: this.data.type == 'freshlyBaked'? this.form.freshlyBakedRoomInfos : this.form.decorationRoomInfos,
          remote: this.data.type == 'freshlyBaked'? getFreshlyRoomList : getRoomList,
          label: this.data.type == 'freshlyBaked'? 'freshlyBakedRoomName' : "decorationRoomName",
          value: this.data.type == 'freshlyBaked'? 'freshlyBakedRoomId' : "decorationRoomId",
          filterable: true,
          multiple: true,
          downLoad: 10,
        },
      },
      {
        model: "remark",
        type: "textarea",
        label: "备注",
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
