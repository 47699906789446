<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="80px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { deliveryShopOrder } from "@/api/produce/decoration/index.js"; //仓库

export default {
  name: "Delivery",
  mixins: [mixin],
  data() {
    return {
      requests: { add: deliveryShopOrder },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    // 门店编号 、 门店名称 、 仓库编号 、 仓库名称
    const formOpt = [
      {
        model: "deliveryShopId",
        type: "local",
        label: "配送方式",
        rules: [
          {
            required: true,
            message: "请选择配送方式",
            trigger: ["blur", "change"],
          },
        ],
        delete: true,
        option: {
          filterable: true,
          label: "label",
          value: "value",
          data: [{ value: "99", label: "商家配送" }],
        },
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
