<template>
  <div class="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="106px">
      <cardTitleCom cardTitle="" class="options">
        <template slot="leftCardTitle">
          <div class="leftContent x-fsa marL15">
            <span class="text" style="width: 160px;margin-right: 20px;">{{ `超${billType == '190101'? '收' : '付'}转预收金额：` }}<span style="color: rgb(255, 117, 0);">{{ exceedAmount }}</span></span>
            <span class="text">优惠金额：</span>
            <el-input class="fl" v-model="discountMoney" placeholder="请输入优惠金额"></el-input>
            <el-tooltip class="fl" effect="dark" :content= "`用于记录${billType == '190101'? '收' : '付'}款时的优惠或折扣`" placement="top">
              <i class="el-icon-question" style="color: #a4a4a4"></i>
            </el-tooltip>
          </div>
        </template>
        <template slot="cardContent">
          <div class="table">
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
      
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button size="mini" type="primary" @click="subClick()">确定</el-button>
    </div>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import { mixin } from "@/components/Dialog/components/mixin.js";
import { fcount } from '@/utils'

export default {
  name: "SourceCollection",
  mixins: [mixin],
  dicts: ['bill_status'],
  components: { EditTable, cardTitleCom },
  data() {
    return {
      billType: '',
      discountMoney: '',
      exceedAmount: '',
      options: {},
      rules: {
        billDate: [
          { required: true, message: '请输入制单日期', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    async handleEvent (type, row) {
      console.log(type, 'type', row)
      //获取元素在数组的下标
      switch (type) {
        case 'getSelectDataPay':
        case 'getSelectData':
          if (!row) return
          let { check } = row
          if (check.length <= 0) return
          if (type == 'getSelectData') {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'bankAccountId', check[0].bankAccountId)
            this.$set(this.options.list[tableIndex], 'bankAccountNo', check[0].bankAccountNo)
            this.$set(this.options.list[tableIndex], 'bankAccountName', check[0].bankAccountName)
          } else {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
            this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
            this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
          }
          break
        case 'tableIndex':
          this.options.tableIndex = row
          break
        default:
          break
      }
    },
  },
  created() {
    console.log(this.data, 'data')
    this.exceedAmount = this.data.exceedAmount
    this.billType = this.data.billType
    this.options = {
      tableIndex: 0,
      curListIndex: -1,
      mutiSelect: false,
      list: this.data.list,
      curListIndex: -1,
      tableCellLabel: '序号',
      tableHeight: 200,
      status: undefined,
      hideTotal: true,
      retainColumn: true,
      columns: [
        {
          prop: 'bankAccountNo',
          label: `${this.billType == '190101'? '收' : '付'}款账号`,
          type: 'remoteSelect',
          click: 'clickAccount',
          Width: 200,
          align: 'center',
          option: this.$select({
            key: 'account',
            option: {
              option: {
                label: 'bankAccountName',
                value: 'bankAccountNo',
                modal: 'bankAccountNo',
                change: (propValue, row) => {
                  console.log(propValue, row)
                  let tableIndex = this.options.tableIndex
                  if (row && this.options.tableIndex != undefined) {
                    this.$set(this.options.list[tableIndex], 'bankAccountId', row.bankAccountId)
                    this.$set(this.options.list[tableIndex], 'bankAccountNo', row.bankAccountNo)
                    this.$set(this.options.list[tableIndex], 'bankAccountName', row.bankAccountName)
                  } 
                },
                tableChange: (propValue, row) => {
                  this.handleEvent('getSelectData', row)
                },
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择账户',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: {
                        ...this.$dialog({ key: 'account' }),
                        table: {
                          ...this.$dialog({ key: 'account' }).table,
                          mutiSelect: true
                        }
                      }
                    }
                  }
                ]
              }
            }
          }).option,
          disabled: false,
          rules: true
        },
        {
          prop: 'payModeNo',
          label: `${this.billType == '190101'? '收' : '付'}款方式`,
          type: 'remoteSelect',
          click: 'clickAccount',
          Width: 200,
          align: 'center',
          option: this.$select({
            key: 'paymode',
            option: {
              option: {
                label: 'payModeName',
                value: 'payModeNo',
                modal: 'payModeNo',
                tableChange: (propValue, row) => {
                  this.handleEvent('getSelectDataPay', row)
                },
                change: (propValue, row) => {
                  console.log(propValue, row)
                  let tableIndex = this.options.tableIndex
                  if (row && this.options.tableIndex != undefined) {
                    this.$set(this.options.list[tableIndex], 'payModeId', row.payModeId)
                    this.$set(this.options.list[tableIndex], 'payModeName', row.payModeName)
                    this.$set(this.options.list[tableIndex], 'payModeNo', row.payModeNo)
                  } 
                },
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: `选择${this.billType == '190101'? '收' : '付'}款账号`,
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: {
                        ...this.$dialog({ key: 'paymode' }),
                        table: {
                          ...this.$dialog({ key: 'paymode' }).table,
                          mutiSelect: true
                        }
                      }
                    }
                  }
                ]
              }
            }
          }).option,
          disabled: false,
          rules: true
        },
        {
          prop: 'payMoney',
          label: `${this.billType == '190101'? '收' : '付'}款金额`,
          minWidth: 120,
          align: 'center',
          type: 'input',
          disabled: false,
          rules: true,
          isMinus: true,
        },
        {
          prop: 'remark',
          label: '备注',
          minWidth: 120,
          align: 'center',
          type: 'input',
          disabled: false
        }
      ],
      summary: ['payMoney'],
    }
  }
};
</script>


<style lang="scss" scoped>
  .wrap {
    width: 1000px;
    .img{
      background: url(~@/assets/imgs/loginimgs/tips.png) no-repeat 100% 100%;
      width: 170px;
      height: 100px;
      margin: 30px auto;
    }
    .tips {
      color: #333;
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .leftContent {
    .text {
      width: 70px;
      font-size: 14px;
      line-height: 40px;
    }
    .el-icon-question {
      margin-top: 10px;
    }
    ::v-deep .el-input--medium {
      width: 140px;
    }
    ::v-deep .el-input__inner {
      border-radius: 0;
      border: none;
      border-bottom: 2px solid #ccc;
    }
  }
</style>
