var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.options,
              rules: _vm.rules,
              "label-width": "106px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.classificationType == 1 ? "收入编码" : "支出编码",
                  prop: "rpTypeCategoryNo",
                },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.options.rpTypeCategoryNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "rpTypeCategoryNo", $$v)
                    },
                    expression: "options.rpTypeCategoryNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.classificationType == 1 ? "收入名称" : "支出名称",
                  prop: "rpTypeCategoryName",
                },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.options.rpTypeCategoryName,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "rpTypeCategoryName", $$v)
                    },
                    expression: "options.rpTypeCategoryName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.classificationType == 1 ? "收入分类" : "支出分类",
                  prop: "parentId",
                },
              },
              [
                _c("treeselect", {
                  attrs: {
                    options: _vm.categoryList,
                    "show-count": true,
                    placeholder: "请选择分类",
                    normalizer: _vm.normalizer,
                  },
                  on: { input: _vm.inputSelect },
                  model: {
                    value: _vm.options.parentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "parentId", $$v)
                    },
                    expression: "options.parentId",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排序", prop: "sortNo" } },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off" },
                  model: {
                    value: _vm.options.sortNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "sortNo", _vm._n($$v))
                    },
                    expression: "options.sortNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "备注", prop: "remark" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.options.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "remark", $$v)
                    },
                    expression: "options.remark",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("dialogEvent", "dialogClose")
              },
            },
          },
          [_vm._v("取 消")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.categorySubmit } },
          [_vm._v("确 定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }