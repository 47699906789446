var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("TablePage", {
          ref: "tablePage",
          on: { handleEvent: _vm.handleEvent },
          model: {
            value: _vm.options,
            callback: function ($$v) {
              _vm.options = $$v
            },
            expression: "options",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
          [_vm._v(" 确 认 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }