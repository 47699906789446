var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.options,
              rules: _vm.rules,
              "label-width": "106px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "编码", prop: "settlementTermNo" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.options.settlementTermNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "settlementTermNo", $$v)
                    },
                    expression: "options.settlementTermNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "名称", prop: "settlementTermName" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.options.settlementTermName,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "settlementTermName", $$v)
                    },
                    expression: "options.settlementTermName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "结算期限", prop: "settlementTermDays" } },
              [
                _c("el-input", {
                  attrs: { onkeyup: "value=value.replace(/[^\\d]/g,'')" },
                  model: {
                    value: _vm.options.settlementTermDays,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "settlementTermDays", $$v)
                    },
                    expression: "options.settlementTermDays",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "对账日为每日",
                  prop: "settlementCheckAccountDay",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "2",
                    minlength: "1",
                    onkeyup: "value=value.replace(/[^\\d]/g,'')",
                  },
                  model: {
                    value: _vm.options.settlementCheckAccountDay,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "settlementCheckAccountDay", $$v)
                    },
                    expression: "options.settlementCheckAccountDay",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排序" } },
              [
                _c("el-input", {
                  attrs: { onkeyup: "value=value.replace(/[^\\d]/g,'')" },
                  model: {
                    value: _vm.options.sortNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "sortNo", $$v)
                    },
                    expression: "options.sortNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "备注" } },
              [
                _c("el-input", {
                  staticClass: "inputWidth",
                  attrs: {
                    size: "mini",
                    type: "textarea",
                    placeholder: "备注长度介于 1 和 80 字符之间",
                    maxlength: "80",
                    autosize: { minRows: 1, maxRows: 2 },
                    disabled: _vm.disableOperate,
                  },
                  model: {
                    value: _vm.options.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "remark", $$v)
                    },
                    expression: "options.remark",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("dialogEvent", "dialogClose")
              },
            },
          },
          [_vm._v("取 消")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.categorySubmit } },
          [_vm._v("确 定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }