<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="80px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { getAllShopInfo } from "@/api/shop/base/shopInfo";
import { getShopAllStore, deliveryShopUpdate } from "@/api/produce/decoration/index.js"; //仓库

export default {
  name: "DeliveryShop",
  mixins: [mixin],
  data() {
    return {
      requests: { add: deliveryShopUpdate },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    // 门店编号 、 门店名称 、 仓库编号 、 仓库名称
    const formOpt = [
      {
        model: "deliveryShopId",
        type: "local",
        label: "门店名称",
        rules: [
          {
            required: true,
            message: "请选择门店",
            trigger: ["blur", "change"],
          },
        ],
        option: {
          remote: getAllShopInfo,
          dataKey: "data",
          label: "shopName",
          value: "shopId",
          filterable: true,
          labels: [
            { title: "门店编号", label: "shopNo" },
            { title: "门店名称", label: "shopName" },
          ],
          change: () => {
            this.form.storeId = "";
          },
        },
      },
      {
        label: "仓库名称",
        type: "local",
        model: "storeId",
        option: {
          openRemote: true, // 打开时搜索
          filterable: true,
          value: "storeId",
          label: "storeName",
          remoteBody: () => ({ shopId: this.form.deliveryShopId || 0 }),
          remote: getShopAllStore,
          dataKey: "data",
          labels: [
            { title: "仓库编号", label: "storeNo" },
            { title: "仓库名称", label: "storeName" },
          ],
        },

        rules: [
          {
            required: true,
            message: "请选择仓库",
            trigger: ["blur"],
          },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
