var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.step === 0
          ? _c("TablePage", {
              ref: "tablePage",
              model: {
                value: _vm.options,
                callback: function ($$v) {
                  _vm.options = $$v
                },
                expression: "options",
              },
            })
          : _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.form.couponEffectMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "couponEffectMode", $$v)
                      },
                      expression: "form.couponEffectMode",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marB20" },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("即时生效"),
                        ]),
                        _c("span", { staticClass: "fontS14 marR10" }, [
                          _vm._v("生效后有效时长"),
                        ]),
                        _c("el-input-number", {
                          staticStyle: {
                            width: "140px",
                            "margin-right": "4px",
                          },
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 1,
                            controls: false,
                            disabled: _vm.form.couponEffectMode != 0,
                          },
                          model: {
                            value: _vm.form.couponValidDays,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponValidDays", $$v)
                            },
                            expression: "form.couponValidDays",
                          },
                        }),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("天"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "marB20" },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("延迟生效"),
                        ]),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("领取"),
                        ]),
                        _c("el-input-number", {
                          staticStyle: {
                            width: "140px",
                            "margin-right": "4px",
                          },
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 1,
                            controls: false,
                            disabled: _vm.form.couponEffectMode != 1,
                          },
                          model: {
                            value: _vm.form.couponEffectDays,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponEffectDays", $$v)
                            },
                            expression: "form.couponEffectDays",
                          },
                        }),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("天后生效"),
                        ]),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v(", 生效后有效时长"),
                        ]),
                        _c("el-input-number", {
                          staticStyle: {
                            width: "140px",
                            "margin-right": "4px",
                          },
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 1,
                            controls: false,
                            disabled: _vm.form.couponEffectMode != 1,
                          },
                          model: {
                            value: _vm.form.couponValidDays,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponValidDays", $$v)
                            },
                            expression: "form.couponValidDays",
                          },
                        }),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("天"),
                        ]),
                        _c(
                          "span",
                          { staticClass: "fontS14 annotateGrey marR20" },
                          [_vm._v(" (优惠券生效时间从当天的00:00开始) ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("固定日期生效"),
                        ]),
                        _c("el-date-picker", {
                          staticClass: "marR20",
                          attrs: {
                            disabled: _vm.form.couponEffectMode != 2,
                            type: "datetime",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.form.couponEffectDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponEffectDate", $$v)
                            },
                            expression: "form.couponEffectDate",
                          },
                        }),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v(", 生效后有效时长"),
                        ]),
                        _c("el-input-number", {
                          staticStyle: {
                            width: "140px",
                            "margin-right": "4px",
                          },
                          attrs: {
                            precision: 0,
                            step: 1,
                            min: 1,
                            controls: false,
                            disabled: _vm.form.couponEffectMode != 2,
                          },
                          model: {
                            value: _vm.form.couponValidDays,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponValidDays", $$v)
                            },
                            expression: "form.couponValidDays",
                          },
                        }),
                        _c("span", { staticClass: "fontS14 marR20" }, [
                          _vm._v("天"),
                        ]),
                        _c(
                          "span",
                          { staticClass: "fontS14 annotateGrey marR20" },
                          [_vm._v("(优惠券生效时间从当天的00:00开始)")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("dialogEvent", "dialogClose")
              },
            },
          },
          [_vm._v("取 消")]
        ),
        _vm.step === 0
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.setStep(1)
                  },
                },
              },
              [_vm._v(" 下一步 ")]
            )
          : _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.setStep(-1)
                  },
                },
              },
              [_vm._v("上一步")]
            ),
        _vm.step === 1
          ? _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submit(1)
                  },
                },
              },
              [_vm._v(" 确 认 ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }