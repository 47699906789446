<template>
  <!-- 左tree 右表格 组件 -->
  <div class="dialog-content" v-if="dialog.show">
    <div class="content" ref="content" v-if="step == 1">
      <div class="left-tree" v-if="formData.treeApi">
        <div class="searchTreeBox">
          <el-input size="mini" class="searchTree" placeholder="输入关键字进行过滤" v-model="formData.treeQuery"
            prefix-icon="el-icon-search">
          </el-input>
        </div>
        <el-scrollbar>
          <el-tree v-if="treeList.length > 0" class="treeBody" highlight-current :data="treeList"
            :node-key="formData.defaultProps.id" :props="formData.defaultProps" ref="tree"
            :filter-node-method="filterNode" @node-click="handleNodeClick" :expand-on-click-node="false"
            :default-expand-all="true" :tree-props="formData.treeProps">
          </el-tree>
        </el-scrollbar>
      </div>
      <div class="right-table">
        <div class="searchTableBox" v-if="formData.search">
          <SelectAllFilter  v-if="formData.search" v-model="formData.queryParams" :filters="formData.search.filters" />
           <div v-if="formData.isSupplier" class="x-f">
              <div style="width:60px">供应商：</div>
              <SelectRemote
              style="margin-right:10px;width:200px" 
                v-model="formData.queryParams.supplierId"
                :option="
                  $select({
                    key: 'listSupplier',
                    option: {
                      option: { 
                       clearable: true,
                        // remoteBody: { storeTypes: [1, 2] }
                      },
                    },
                  }).option
                "
              />
          </div>
          <el-button size="mini" type="primary" @click="handleEvent('search')">搜索</el-button>
        </div>
        <div class="tableBox">
          <Table :height="`${tableHeight}px`" ref="table" reserve-selection :muti-select="true"
            :operate-width="`${formData.table.operateWidth || 200}`" :list="tableList || []"
            :loading="this.formData.table.loading" :row-key="formData.table.rowKey" :border="true"
            :mutiSelect="formData.table.mutiSelect" @select="
              (selection, row) => handleEvent('selection', { selection, row })
            " @select-all="
              selection =>
                handleEvent('selection', { selection, row: tableList })
            " @rowClick="
              row =>
                formData.table.rowClick
                  ? handleEvent('rowClick', row)
                  : undefined
            " @handleEvent="handleEvent">
            <template v-slot:table-column v-if="formData.table.columns">
              <el-table-column v-if="formData.table.radioSelect && !formData.table.mutiSelect" width="50" fixed
                label="选择" align="center" class-name="radioSelect" header-align="center" type="">
                <template slot-scope="{ row }">
                  <el-radio v-model="radioSelect" :label="row">
                    {{ '' }}
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column width="50" label="序号" v-if="formData.table.listNo" align="center" header-align="center"
                :resizable="false">
                <template slot-scope="{ $index }">
                  <span v-if="
                      formData.table.pagination &&
                      formData.table.pagination.pageSize
                    ">{{
                      $index +
                      1 +
                      (formData.table.pagination.pageNum - 1) *
                        formData.table.pagination.pageSize
                    }}</span>
                  <span v-else>{{ $index + 1 }}</span>
                </template>
              </el-table-column>
              <TableColumns v-for="(item, i) in formData.table.columns" :key="item.prop + i" :column="item"
                @handleEvent="handleEvent">
              </TableColumns>
            </template>
          </Table>
          <TablePagination v-if="formData.table.pagination" :isSelect="formData.table.mutiSelect "
            :check.sync="formData.table.check" :page.sync="formData.table.pagination.pageNum"
            :limit.sync="formData.table.pagination.pageSize" :total="formData.table.pagination.total"
            :filter="formData.table.pagination.filter" :auto-scroll="formData.table.pagination.autoScroll"
            @pagination="obj => handleEvent('pagination', obj)" />
        </div>
      </div>
    </div>
    <!-- 优惠券参数 -->
    <CouponParameter :isGrant="dialog.isGrant" :isNumber="dialog.isNumber" :isText="dialog.isText"
      :isQuantity="dialog.isQuantity" ref="CouponParameter" class="step-two" v-else />
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button v-if="dialog.isStep && step == 1" @click="nextStep">下 一 步</el-button>
      <el-button v-if="dialog.isStep && step == 2" @click="step = 1">上 一 步</el-button>
      <el-button type="primary" @click="handleEvent('confirm')" v-if="(dialog.isStep && step == 2) || !dialog.isStep">确
        定</el-button>
    </div>
  </div>
</template>

<script>
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import Table from '@/components/tablePage/table'
import TablePagination from '@/components/tablePage/tablePagination'
import CouponParameter from '@/components/Dialog/components/components/CouponParameter.vue'
export default {
  name: 'TreeAndTable',
  model: { prop: 'formData', event: 'Device' },
  props: {
    formData: {
      type: Object
    },
    dialog: {
      type: Object
    }
  },
  components: {
    Table,
    TableColumns: () => import('@/components/tablePage/tableColumns'),
    SelectAllFilter: () =>
      import('@/components/tablePage/select/select-all-filter/index.vue'),
    TablePagination,
    CouponParameter,
    SelectRemote,
  },
  watch: {
    'dialog.show': {
      async handler(val) {
        if (val) {
          await this.$nextTick()
          this.getTreeList()
          this.handleEvent('search')
          this.tableHeight = this.$refs.content.offsetHeight - 108
        }
      },
      immediate: true
    },
    // tree数据过滤
    'formData.treeQuery'(val) {
      this.$refs.tree.filter(val)
    }
  },
  data() {
    return {
      treeList: [],
      tableList: [],
      tableHeight: 0, //表格高度
      radioSelect: null,
      step: 1 //步骤
    }
  },
  methods: {
    //回显选中
    handleEcho() {
      let ids = this.formData.table?.echoChecked || []
      if (ids.length >= 1) {
        const table = this.$refs.table.$refs.mutipleTable
        let checked = []
        this.tableList.forEach(item => {
          if (ids.includes(item[this.formData.table.rowKey])) {
            table.toggleRowSelection(item, true)
            checked.push(item)
          }
        })
        this.handleEvent('selection', {
          selection: [...this.formData.table.check, ...checked]
        })
      }
    },
    async handleEvent(type, row) {
      // console.log(this.formData.table.check,type,row,'88')
      switch (type) {
        case 'search': // 搜索
          this.formData.table.pagination.pageNum = 1 // 页码重置
          await this.getTableList()
          break
        case 'pagination': // 翻页/切换每页显示数
          await this.getTableList()
          break
        case 'selection':
          this.formData.table.check = row.selection
          break
        case 'rowClick':
          if (this.formData.table.mutiSelect) {
            const index = this.formData.table.check.findIndex(
              y =>
                y[this.formData.table.rowKey] ===
                row[this.formData.table.rowKey]
            )
            if (index >= 0) {
              this.formData.table.check.splice(index, 1)
            } else {
              this.formData.table.check = [...this.formData.table.check, row]
            }
            this.$refs.table.$refs.mutipleTable.toggleRowSelection(row)
          } else if (this.formData.table.radioSelect) {
            this.radioSelect =
              this.radioSelect?.[this.formData.table.rowKey] ==
                row[this.formData.table.rowKey]
                ? undefined
                : row
          }
          break
        case 'confirm':
          if (this.formData.table.radioSelect) {
            //判断是否执行了下一步(主要用于优惠券) , 下一步需要校验
            if (this.dialog.isStep) {
              try {
                await this.$refs.CouponParameter.$refs.form.validate()
                this.formData.table.check[0] = {
                  ...this.radioSelect,
                  ...this.$refs.CouponParameter.couponPar,
                  ...this.assemblyCoupon(this.$refs.CouponParameter.couponPar)
                }
              } catch (err) {
                return
              }
            } else {
              this.formData.table.check[0] = this.radioSelect
            }
          }
          this.$emit('dialogEvent', 'dialogChange')
          break
        case 'close':
          this.$emit('dialogEvent', 'dialogClose')
          break
        default:
          break
      }
    },
    //获取tree数据
    async getTreeList() {
      if (!this.formData.treeApi) return
      try {
        const res = await this.formData.treeApi({ pageSize: 999999 })
        this.treeList = res.data || res.rows
      } catch (err) { }
    },
    //获取表格数据
    getTableList() {
        // this.formData.searchList.forEach(item => {
        //   item.option
        //   })
        console.log('this.formData', this.formData);
      return new Promise(async (resolve, reject) => {
        this.formData.table.loading = true
        try {
          const res = await this.formData.table.list({
            ...this.formData.table.pagination,
            ...this.formData.queryParams,
            total: ''
          })
          this.$refs.table.$refs.mutipleTable.clearSelection()
          this.formData.table.check = []
          this.tableList = this.formData.table?.isTenant ? res.data.list : res.rows || res.data
          this.formData.table.pagination.total = this.formData.table?.isTenant ? res.data.total : res.total
          //回显
          this.handleEcho()
        } catch (err) { }
        this.formData.table.loading = false
        resolve()
      })
    },
    // 节点单击事件
    handleNodeClick(data) {
      let idField =
        this.formData.defaultProps.searchId || this.formData.defaultProps.id
      this.formData.table.pagination[idField] =
        data[this.formData.defaultProps.id]
      // console.log(this.formData)
      this.handleEvent('search')
    },
    // 筛选节点
    filterNode(value, data) {
      // console.log(data.value)
      if (!value) return true
      return data[this.formData.defaultProps.label].indexOf(value) !== -1
    },
    //下一步(主要用于优惠券)
    async nextStep() {
      let couponCaseName = undefined
      if (this.formData.table.mutiSelect) {
        couponCaseName = this.formData.table.check
          .map(item => item.couponCaseName)
          .join(',')
      } else if (this.formData.table.radioSelect) {
        couponCaseName = this.radioSelect.couponCaseName
      }
      this.step = 2
      await this.$nextTick()
      this.$refs.CouponParameter.couponPar.couponCaseName = couponCaseName
    },
    //组装优惠券数据
    assemblyCoupon(value) {
      //发放方式
      const couponEmitModes = {
        0: '即时发放',
        1: '按天分期发放',
        2: '按周分期发放',
        3: '按月分期发放'
      }
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval
      } = value
      let couponEmitModeName = couponEmitModes[couponEmitMode]
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit =
          couponEmitMode === 1 ? '天' : couponEmitMode === 2 ? '周' : '月'
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`
      }
      //生效方式
      const couponEffectModes = {
        0: '即时生效， ',
        1: '延迟生效， ',
        2: '固定日期生效， '
      }
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays
      } = value
      let couponEffectModeName = couponEffectModes[couponEffectMode]
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
      }
      value.couponEmitModeName = couponEmitModeName
      value.couponEffectModeName = couponEffectModeName
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  height: 65vh;
  display: flex;
}
.left-tree {
  width: 25%;
  border: 1px solid #dfe6ec;
  padding: 10px;
  height: calc(100% - 40px);
}
.right-table {
  flex: 1;
  width: calc(75% - 10px);
  margin-left: 10px;
  .searchTableBox {
    display: flex;
    margin-top: 10px;
    // .el-input {
    //   width: 30%;
    //   margin-right: 10px;
    // }
    ::v-deep .el-input__suffix{
      .i, .el-input__validateIcon{
        display: none;
      }
    }
  }
  .tableBox {
    margin-top: 10px;
  }
}

::v-deep .radioSelect {
  .el-radio__label {
    display: none !important;
  }

  .el-radio__original {
    z-index: 1 !important;
    cursor: pointer;
  }
}

::v-deep .select-all-filter {
  width: 350px !important;
  margin-right: 10px !important;
}
::v-deep .el-scrollbar {
  height: calc(100% - 38px);
  margin-top: 10px;
}
// 侧边滚动条显示
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}
::v-deep .pagination-view {
  height: 50px;
}
</style>
