<template>
  <div class="dialog-content">
    <div class="content">
      <el-form ref="form" :model="options" :rules="rules" label-width="106px">
        <el-form-item :label="classificationType == 1? '收入编码' : '支出编码'" prop="rpTypeNo">
          <el-input v-model="options.rpTypeNo"></el-input>
        </el-form-item>
        <el-form-item :label="classificationType == 1? '收入名称' : '支出名称'" prop="rpTypeName">
          <el-input v-model="options.rpTypeName"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="classificationType == 1? '收入分类' : '支出分类'"  prop="rpTypeCategoryId">
          <el-tree :data="categoryList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-form-item> -->
        <el-form-item :label="classificationType == 1? '收入分类' : '支出分类'" prop="rpTypeCategoryId">
          <treeselect
            v-model="options.rpTypeCategoryId"
            :options="categoryList"
            :show-count="true"
            placeholder="请选择分类"
            :normalizer="normalizer"
            @input="inputSelect"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="categorySubmit">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "CategoryOperationList",
  mixins: [mixin],
  components: {
    Treeselect
  },
  data() {
    return {
      options: {
        rpTypeNo: '',
        rpTypeName: '',
        rpTypeCategoryId: '',
      },
      classificationType: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      categoryList: [],
      rules: {
        rpTypeNo: [
          { required: true, message: '请输入编码', trigger: 'blur' }
        ],
        rpTypeName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        rpTypeCategoryId: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
      },
    };
  },
  async created() {
    console.log(this.data, 'data');
    this.options = this.data.obj
    this.type = this.data.type
    this.classificationType = this.data.classificationType
    this.categoryList = this.data.list
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
      this.options.rpTypeCategoryId = data.rpTypeCategoryId
    },
    inputSelect () {
      this.$refs.form.validateField('rpTypeCategoryId')
    },
    normalizer (node) {
      return {
        id: node.rpTypeCategoryId,
        label: node.label,
        children: node.children
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .dialog-content {
    width: 400px;
  }
</style>
