<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { addBankAccount, modifyBankAccount } from '@/api/goods/finance'
export default {
  name: "FinanceCheck",
  mixins: [mixin],
  data() {
    return {
      dialogOptions: {
        type: "FinanceCheck",
        show: false,
      },
      requests: {},
      show: false,
      oldText: "",
      formOpt: [],
    };
  },
  async created() {
    let url = this.data? modifyBankAccount : addBankAccount
    this.requests.add = url
    const formOpt = [
      {
        model: "bankAccountNo",
        type: "input",
        label: "账户编码",
        loadFormat: (v) => {
          let text = this.data ? this.data.bankAccountNo : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入账户编码",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "bankAccountName",
        type: "input",
        label: "账户名称",
        loadFormat: (v) => {
          let text = this.data ? this.data.bankAccountName : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入账户名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "bankNo",
        type: "input",
        label: "银行账号",
        loadFormat: (v) => {
          let text = this.data ? this.data.bankNo : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入银行账号",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "bankTitle",
        type: "input",
        label: "开户名",
        loadFormat: (v) => {
          let text = this.data ? this.data.bankTitle : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入开户名",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "bankName",
        type: "input",
        label: "开户行",
        loadFormat: (v) => {
          let text = this.data ? this.data.bankName : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入开户行",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "remark",
        type: "input",
        label: "备注",
        loadFormat: (v) => {
          let text = this.data ? this.data.remark : ''
          return text;
        },
        rules: [
          {
            required: true,
            message: "请输入备注",
            trigger: ["blur", "change"],
          },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
