var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("Table", {
          ref: "table",
          attrs: {
            "reserve-selection": "",
            "muti-select": false,
            list: _vm.tableList || [],
            loading: _vm.loading,
            "row-key": "vipId",
            border: true,
            height: "500",
          },
          scopedSlots: _vm._u(
            [
              _vm.columns
                ? {
                    key: "table-column",
                    fn: function () {
                      return [
                        _c("el-table-column", {
                          attrs: {
                            width: "50",
                            label: "序号",
                            align: "center",
                            "header-align": "center",
                            resizable: false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var $index = ref.$index
                                  return [
                                    _c("span", [_vm._v(_vm._s($index + 1))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1065934640
                          ),
                        }),
                        _vm._l(_vm.columns, function (item, i) {
                          return _c(
                            "TableColumns",
                            {
                              key: item.prop + i,
                              attrs: { column: item },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "slot-" + item.prop,
                                    fn: function (scope) {
                                      return [
                                        _vm._t(item.prop, null, null, scope),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              item.type === "slots"
                                ? _vm._t(item.prop, null, { slot: item.prop })
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        }),
        _vm.pagination
          ? _c("TablePagination", {
              attrs: {
                page: _vm.pagination.pageNum,
                limit: _vm.pagination.pageSize,
                total: _vm.pagination.total,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.pagination, "pageNum", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.pagination, "pageSize", $event)
                },
                pagination: function (obj) {
                  return _vm.handleEvent("pagination", obj)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }