// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/imgs/loginimgs/tips.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrap[data-v-b116a10a] {\n  width: 1000px;\n}\n.wrap .img[data-v-b116a10a] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;\n  width: 170px;\n  height: 100px;\n  margin: 30px auto;\n}\n.wrap .tips[data-v-b116a10a] {\n  color: #333;\n  font-size: 14px;\n  text-align: center;\n  margin-bottom: 20px;\n}\n.leftContent .text[data-v-b116a10a] {\n  width: 70px;\n  font-size: 14px;\n  line-height: 40px;\n}\n.leftContent .el-icon-question[data-v-b116a10a] {\n  margin-top: 10px;\n}\n.leftContent[data-v-b116a10a]  .el-input--medium {\n  width: 140px;\n}\n.leftContent[data-v-b116a10a]  .el-input__inner {\n  border-radius: 0;\n  border: none;\n  border-bottom: 2px solid #ccc;\n}", ""]);
// Exports
module.exports = exports;
