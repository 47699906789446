<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { shopHotKeySave, shopHotKeyUpdate } from "@/api/shop/setup/hotKey.js";
import { mixin } from "@/components/Dialog/components/mixin.js";
import { getHotKey } from "./keycode.js";

export default {
  name: "HotKey",
  mixins: [mixin],
  data() {
    return {
      requests: { add: shopHotKeySave, update: shopHotKeyUpdate },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      this.$select({
        key: "shop_hot_key",
        option: {
          option: {
            change: (val, row) => {
              this.form.hotKeyName = row.dictLabel;
            },
          },
          filter: "hotKeyNo",
          model: "hotKeyNo",
          seniorSearch: true,
          labelWidth: 90,
          rules: [
            {
              required: true,
              message: "请选择热键功能",
              trigger: ["blur", "change"],
            },
          ],
        },
      }),
      {
        model: "hotKeyValueName",
        type: "input",
        label: "热键",
        readonly: true,
        keydown: async (e) => {
          try {
            const { hotKeyName, hotKeyValue } = await getHotKey(e);
            this.form.hotKeyValueName = hotKeyName;
            this.form.hotKeyValue = hotKeyValue;
          } catch (err) {
            this.$message.error(err);
          }
        },
        rules: [
          {
            required: true,
            message: "请输入热键",
            trigger: ["blur", "change"],
          },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
