<template>
  <!-- 积分兑换商品 -->
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="125px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click + '-save')"
        >保存并新增</el-button
      >
      <el-button type="primary" @click="dialogEvent(dialog.click)">保存</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { goodsUpdate, addGoodsSave } from "@/api/vip/base/scoreExchangeRule"; //会员积分兑换商品

export default {
  name: "ScoreExchangeGoodsRule",
  mixins: [mixin],
  data() {
    return {
      requests: { add: addGoodsSave, update: goodsUpdate },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      this.$select({
        key: "listGoods",
        getModel: true,
        option: {
          option: {
            change: (val, row) => {
              console.log("进来", this.formOpt);
              this.formOpt[1].option.data = row[0]?.units || row?.units || [];
            },
            tableChange: (val, row) => {
              this.formOpt[1].option.data = row.check?.units || row?.units || [];
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: {
                    ...this.$dialog({ key: "goods" }),
                    table: {
                      ...this.$dialog({ key: "goods" }).table,
                      radioSelect: true,
                      mutiSelect: false,
                    },
                  },
                },
              },
            ],
          },
          rules: [
            {
              required: true,
              message: "请选择商品",
              trigger: ["blur", "change"],
            },
          ],
        },
      }),
      {
        label: "单位",
        model: "unitId",
        type: "local",
        option: {
          data: [],
          label: "unitName",
          value: "unitId",
        },
        rules: [
          {
            required: true,
            message: "请选择基本单位",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        label: "适用渠道",
        type: "checkbox-more",
        model: "AllShop",
        loadFormat: (v) => (Array.isArray(v) ? v : ["0", "1"]),
        option: {
          data: [
            { label: "线下门店", value: "0" },
            { label: "线上商城", value: "1" },
          ],
          label: "label",
          value: "value",
        },
        rules: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "equalScore",
        type: "input",
        label: "等价积分",
        rules: [
          {
            required: true,
            message: "请输入等价积分",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "addBuyPrice",
        type: "input",
        label: "加购金额",
      },
      {
        model: "goodsEmitQty",
        type: "input",
        label: "总发行兑换数量",
        rules: [
          {
            required: true,
            message: "请输入总发行兑换数量",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "goodsEveryLimitQty",
        type: "input",
        label: "每人限兑换数量",
        rules: [
          {
            required: true,
            message: "请输入每人限兑换数量",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "datetime",
        type: "date-picker",
        label: "活动有效时间",
        rules: [
          {
            required: true,
            message: "请选择活动有效时间",
            trigger: ["blur", "change"],
          },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
