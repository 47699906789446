<template>
  <div class="dialog-content">
    <div class="content">
      <el-form ref="form" :model="options" :rules="rules" label-width="106px">
        <el-form-item :label="classificationType == 1? '收入编码' : '支出编码'" prop="rpTypeCategoryNo">
          <el-input v-model="options.rpTypeCategoryNo"></el-input>
        </el-form-item>
        <el-form-item :label="classificationType == 1? '收入名称' : '支出名称'" prop="rpTypeCategoryName">
          <el-input v-model="options.rpTypeCategoryName"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="classificationType == 1? '收入分类' : '支出分类'" prop="parentId">
          <el-tree :data="categoryList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-form-item> -->
        <el-form-item :label="classificationType == 1? '收入分类' : '支出分类'" prop="parentId">
          <treeselect
            v-model="options.parentId"
            :options="categoryList"
            :show-count="true"
            placeholder="请选择分类"
            :normalizer="normalizer"
            @input="inputSelect"
          />
        </el-form-item>
        <el-form-item label="排序" prop="sortNo">
          <el-input v-model.number="options.sortNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="options.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <!-- <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button> -->
      <el-button type="primary" @click="categorySubmit">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "CategoryOperation",
  mixins: [mixin],
  components: {
    Treeselect
  },
  data() {
    return {
      options: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      categoryList: [],
      classificationType: '',
      rules: {
        rpTypeCategoryNo: [
          { required: true, message: '请输入编码', trigger: 'blur' }
        ],
        rpTypeCategoryName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        parentId: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        sortNo: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
      },
    };
  },
  async created() {
    console.log(this.data, 'data')
    this.options = this.data.obj
    this.categoryList = this.data.list
    this.classificationType = this.data.classificationType
  },
  methods: {
    inputSelect () {
      this.$refs.form.validateField('parentId')
    },
    normalizer (node) {
      return {
        id: node.rpTypeCategoryId,
        label: node.label,
        children: node.children
      }
    },
    handleNodeClick(data) {
      console.log(data);
      this.options.parentId = data.rpTypeCategoryId
    }
  },
};
</script>

<style lang="scss" scoped>
  .dialog-content {
    width: 400px;
  }
</style>
