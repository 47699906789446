<template>
  <div class="wrap">
    <div class="img"></div>
    <div class="tips">{{billType == 190101? '收款' : '付款'}}核销总金额大于本次核销总金额，请确认处理方式。</div>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button size="mini" type="primary" @click="userClick(1)">计入优惠金额</el-button>
      <el-button size="mini" type="primary" @click="userClick(2)">转为预{{billType == 190101? '收' : '付'}}款</el-button>
    </div>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { mixin } from "@/components/Dialog/components/mixin.js";

export default {
  name: "SourceOperation",
  mixins: [mixin],
  dicts: ['bill_status'],
  components: { TablePage },
  data() {
    return {
      billType: '',
      partnerId: '',
      options : {},
    };
  },
  created() {
    this.billType = this.data.billType
  }
};
</script>


<style lang="scss" scoped>
  ::v-deep  #table-page{
    height: 500px !important;
    width: 1000px;
  }
  .wrap {
    width: 600px;
    .img{
      background: url(~@/assets/imgs/loginimgs/tips.png) no-repeat 100% 100%;
      width: 170px;
      height: 100px;
      margin: 30px auto;
    }
    .tips {
      color: #333;
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
</style>
