<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { 
  listBill, 
  listSourceReceiveBill,
  preArSourceList,
  receiveListSourceReceiveBill,
  listSourcePaymentBill,
  purchaseBillPreApSourceList,
  paymentListSourcePaymentBill,
  listSourceInitialPartnerArBill,
  listSourceInitialPartnerApBill,
} from '@/api/arap/index'
import { mixin } from "@/components/Dialog/components/mixin.js";

import { fcount } from '@/utils'


export default {
  name: "SelectSource",
  mixins: [mixin],
  dicts: ['bill_status'],
  components: { TablePage },
  data() {
    return {
      billType: '',
      partnerId: '',
      options : {},
    };
  },
  created() {
    this.billType = this.data.billType
    console.log(this.data, 'data')
    let tabsArray = [
      {
        billType: ['190204', '190301'],
        title: "预付款单",
        getListApi: paymentListSourcePaymentBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "purchaseOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName?  row.partnerInfo?.areaName: '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName?  row.partnerInfo?.groupName: '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billRpMoney",
            label: "付款金额合计",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "预付款总金额",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 150,
          },
          {
            prop: "billRpMoney",
            label: "付款金额",
            minWidth: 150,
          },
          {
            prop: "settleStatus",
            label: "单据核销状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未退款' : v == 1? '部分退款' : '已退款）'
          },
          {
            prop: "settleMoney",
            label: "已核销总金额",
            minWidth: 150,
          },
          {
            prop: "noSettleMoney",
            label: "未核销总金额",
            minWidth: 150,
            formatter: (v, row) => (row.billRpMoney && row.settleMoney)? fcount([row.billRpMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "sourceBillNo",
            label: "关联单据",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 150,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 150,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190203'],
        title: "采购单",
        getListApi: purchaseBillPreApSourceList,
        defaultBody: { settleStatus:[0,1], partnerId: this.data.partnerId },
        type: "payment",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "purEmployeeName",
            label: "采购员",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "单据金额",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190202'],
        title: "付款单",
        getListApi: listSourcePaymentBill,
        defaultBody: { partnerIds: [this.data.partnerId], includeBillStatus: [2, 4] },
        type: "payment",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName?  row.partnerInfo?.areaName: '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName?  row.partnerInfo?.groupName: '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billRpMoney",
            label: "付款金额合计",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "付款核销总金额",
            minWidth: 150,
          },
          {
            prop: "settleStatus",
            label: "退款状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未退款' : v == 1? '部分退款' : '已退款）'
          },
          {
            prop: "refundAuditStatus",
            label: "退款审核状态",
            minWidth: 150,
            formatter: (v) => v == 0? '已退款未审核' : v == 1? '已退款部分审核' : '已退款已审核）'
          },
          {
            prop: "settleMoney",
            label: "已退款总金额",
            minWidth: 150,
          },
          {
            prop: "noSettleMoney",
            label: "未退款总金额",
            minWidth: 150,
            formatter: (v, row) => (row.billRpMoney && row.settleMoney)? fcount([row.billRpMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "billRpMoney",
            label: "付款金额",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 150,
          },
          {
            prop: "sourceBillNo",
            label: "源单单号",
            formatter: (v, row) => row.apDetails?.sourceBillNo?  row.apDetails?.sourceBillNo: '',
            minWidth: 120,
          },
          {
            prop: "preRpBillNo",
            label: "关联预付款单",
            minWidth: 150,
          },
          {
            prop: "preRpBillMoney",
            label: "转预付款金额",
            minWidth: 150,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未对账' : '已对账'
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 150,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 150,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190201', '190301'],
        title: "采购入库单",
        getListApi: listBill,
        defaultBody: { settleStatus:[0,1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '110102', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "purchaseReceipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "付款状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未付款' : v == 1? '部分付款' : '已付款'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已付款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未付款金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney],'-') : row.billTaxMoney? row.billTaxMoney : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney],'-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190201', '190202'],
        title: "采购退货单",
        getListApi: listBill,
        defaultBody: { settleStatus:[0,1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '110103', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "purchaseReturn",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "付款状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未付款' : v == 1? '部分付款' : '已付款'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已付款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未付款金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney],'-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190104', '190301'],
        title: "预收款单",
        getListApi: receiveListSourceReceiveBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "saleOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName?  row.partnerInfo?.areaName: '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName?  row.partnerInfo?.groupName: '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billRpMoney",
            label: "收款金额合计",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "预收款总金额",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 150,
          },
          {
            prop: "billRpMoney",
            label: "收款金额",
            minWidth: 150,
          },
          {
            prop: "settleStatus",
            label: "单据核销状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未核销' : v == 1? '部分核销' : '已核销'
          },
          {
            prop: "settleMoney",
            label: "已核销总金额",
            minWidth: 150,
          },
          {
            prop: "noSettleMoney",
            label: "未核销总金额",
            minWidth: 150,
            formatter: (v, row) => (row.billRpMoney && row.settleMoney)? fcount([row.billRpMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "sourceBillNo",
            label: "关联单据",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 150,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 150,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190301'],
        title: "期初预收",
        getListApi: listSourceInitialPartnerArBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "putAway",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "areaName",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 150,
          },
          {
            prop: "arAmount",
            label: "应收金额",
            minWidth: 150,
          },
          {
            prop: "preArAmount",
            label: "预收金额",
            minWidth: 150,
          },
          {
            prop: "balance",
            label: "期初余额",
            formatter: (v, row) => (row.arAmount && row.preArAmount)? fcount([row.arAmount, row.preArAmount],'-') : '',
            minWidth: 150,
          },
          {
            prop: "bizDate",
            label: "业务日期",
            minWidth: 150,
          },
          {
            prop: "settleDate",
            label: "结算日期",
            minWidth: 150,
          },
          {
            prop: "deptNo",
            label: "部门编号",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptNo?  row.deptInfo?.deptNo: '',
          },
          {
            prop: "deptName",
            label: "部门名称",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptName?  row.deptInfo?.deptName: '',
          },
          {
            prop: "employeeNo",
            label: "业务员编号",
            minWidth: 140,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeNo?  row.saleEmployeeInfo?.employeeNo: '',
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            minWidth: 140,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName?  row.saleEmployeeInfo?.employeeName: '',
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190301'],
        title: "期初预付",
        getListApi: listSourceInitialPartnerApBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "pay",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "areaName",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo?  row.partnerInfo?.partnerNo: '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName?  row.partnerInfo?.partnerName: '',
            minWidth: 120,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 150,
          },
          {
            prop: "apAmount",
            label: "应收金额",
            minWidth: 150,
          },
          {
            prop: "preApAmount",
            label: "预收金额",
            minWidth: 150,
          },
          {
            prop: "balance",
            label: "期初余额",
            formatter: (v, row) => (row.apAmount && row.preApAmount)? fcount([row.apAmount, row.preApAmount],'-') : '',
            minWidth: 150,
          },
          {
            prop: "bizDate",
            label: "业务日期",
            minWidth: 150,
          },
          {
            prop: "settleDate",
            label: "结算日期",
            minWidth: 150,
          },
          {
            prop: "deptNo",
            label: "部门编号",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptNo?  row.deptInfo?.deptNo: '',
          },
          {
            prop: "deptName",
            label: "部门名称",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptName?  row.deptInfo?.deptName: '',
          },
          {
            prop: "employeeNo",
            label: "采购员编号",
            minWidth: 140,
            formatter: (v, row) => row.purEmployeeInfo?.employeeNo?  row.purEmployeeInfo?.employeeNo: '',
          },
          {
            prop: "employeeName",
            label: "采购员名称",
            minWidth: 140,
            formatter: (v, row) => row.purEmployeeInfo?.employeeName?  row.purEmployeeInfo?.employeeName: '',
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190103'],
        title: "销售订单",
        getListApi: preArSourceList,
        defaultBody: { settleStatus:[0,1], partnerId: this.data.partnerId },
        type: "saleOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "finishUnitMoney",
            label: "已出库金额",
            minWidth: 150,
          },
          {
            prop: "noUnitMoney",
            label: "未出库金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.finishUnitMoney)? fcount([row.billTaxMoney, row.finishUnitMoney],'-') : ''
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 150,
          },
          // 未核销金额 = 成交金额 - 已核销金额；
          {
            prop: "noSettleMoney",
            label: "未核销金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.settleMoney)? fcount([row.billTaxMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.billNotaxMoney)? fcount([row.billTaxMoney, row.billNotaxMoney],'-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "税价合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190102'],
        title: "收款单",
        getListApi: listSourceReceiveBill,
        defaultBody: { partnerIds: [this.data.partnerId]},
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "partnerName",
            label: "客户编号",
            minWidth: 150,
            formatter: (v, row) => row.partnerInfo?.partnerNo? row.partnerInfo?.partnerNo : ''
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
            formatter: (v, row) => row.partnerInfo?.partnerName? row.partnerInfo?.partnerName : ''
          },
          {
            prop: "billRpMoney",
            label: "收款金额合计",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "收款核销总金额",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "本次核销总金额",
            minWidth: 150,
          },
          {
            prop: "preRpBillNo",
            label: "关联预收款单",
            minWidth: 150,
          },
          {
            prop: "preRpBillMoney",
            label: "转预收款金额",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190301'],
        title: "销售出库单",
        getListApi: listBill,
        defaultBody: { settleStatus:[0,1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '120102', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "salesOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "收款状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未核销' : v == 1? '部分核销' : '已核销'
          },
          {
            prop: "thisAccounts",
            label: "本次应收账款",
            minWidth: 150,
            formatter: (v, row) => v == (row.settleMoney && row.billTaxMoney) ? fcount([row.billTaxMoney, row.settleMoney],'-') : ''
          },
          {
            prop: "settleMoney",
            label: "已收款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未收款金额",
            minWidth: 150,
            formatter: (v, row) => (row.settleMoney && row.billTaxMoney) ? fcount([row.billTaxMoney, fcount([row.billTaxMoney, row.settleMoney],'-') ],'-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney],'-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190102'],
        title: "销售退货单",
        getListApi: listBill,
        defaultBody: {  settleStatus:[0,1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '120103', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "cancelGoods",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "收款状态",
            minWidth: 150,
            formatter: (v) => v == 0? '未核销' : v == 1? '部分核销' : '已核销'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已退款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未退款金额",
            minWidth: 150,
            formatter: (v, row) => (row.settleMoney && row.billTaxMoney) ?  fcount([row.billTaxMoney, fcount([row.billTaxMoney, row.settleMoney],'-')],'-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney && row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney],'-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
    ]
    let list = tabsArray.filter((x) => x.billType.includes(this.billType))
    if (this.data.type == 1) {
      let text = this.data.billSubType == 1? '预收款单' : '预付款单'
      let textOpen = this.data.billSubType == 1? '期初预收' : '期初预付'
      // list = list.filter((x) => x.title == text)
      list = list.filter((x) => [text, textOpen].includes(x.title))
    } else if (this.data.type == 2)  {
      let text = this.data.billSubType == 1? '销售出库单' : '采购入库单'
      list = list.filter((x) => x.title == text)
    }

    this.options =  {
      summary: "",
      listNo: true,
      loading: true,
      pagination: {
        total: 0,
        pageNum: 1,
        pageSize: 15,
      },
      body: {},
      mutiSelect: true, // 多选
      check: [], // 选中数据
      rowKey: "billId",
      title: "",
      tableTitle: '',
      labelWidth: "80",
      tabsColumns: list
    }
    console.log(this.options, 'this.options ')
  }
};
</script>


<style lang="scss" scoped>
  ::v-deep  #table-page{
    height: 500px !important;
    width: 1000px;
  }
</style>
