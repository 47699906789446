// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/imgs/loginimgs/tips.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-fcd7766a] #table-page {\n  height: 500px !important;\n  width: 1000px;\n}\n.wrap[data-v-fcd7766a] {\n  width: 600px;\n  padding: 20px 0;\n}\n.wrap .img[data-v-fcd7766a] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;\n  width: 170px;\n  height: 100px;\n  margin: 30px auto;\n}\n.wrap .tips[data-v-fcd7766a] {\n  color: #333;\n  font-size: 14px;\n  text-align: center;\n  margin-bottom: 20px;\n}", ""]);
// Exports
module.exports = exports;
