var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("el-cascader-panel", {
          attrs: {
            options: _vm.dialog.data,
            props: {
              multiple: true,
              value: "id",
              expandTrigger: "hover",
            },
          },
          model: {
            value: _vm.form.list,
            callback: function ($$v) {
              _vm.$set(_vm.form, "list", $$v)
            },
            expression: "form.list",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("dialogEvent", "dialogClose")
              },
            },
          },
          [_vm._v("取 消")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.submit()
              },
            },
          },
          [_vm._v("确 定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }