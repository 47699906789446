<template>
  <!-- IC/ID发放 -->
  <div class="dialog-content" v-if="dialog.show">
    <el-form :model="formData.list[vipCardNoIndex]" label-width="98px">
      <div class="content">
        <div class="left">
          <div class="left-item">
            <el-form-item label="会员卡号">
              <el-input
                class="dialogInput"
                v-model="formData.list[vipCardNoIndex].vipNo"
                autocomplete="off"
                disabled
              />
            </el-form-item>
            <el-form-item label="会员姓名">
              <el-input
                class="dialogInput"
                v-model="formData.list[vipCardNoIndex].vipName"
                autocomplete="off"
                disabled
              />
            </el-form-item>
            <el-form-item label="实体卡号">
              <el-input
                ref="idPhysicalNo"
                class="dialogInput"
                v-model="idForm.idPhysicalNo"
                @keyup.enter.native="$refs.idPhysicalNoConfirm.focus"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item label="确认实体卡号">
              <el-input
                class="dialogInput"
                ref="idPhysicalNoConfirm"
                v-model="idForm.idPhysicalNoConfirm"
                @keyup.enter.native="cardReade"
                autocomplete="off"
              />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button
        type="success"
        @click="cardNext"
        :disabled="this.formData.list.length == this.vipCardNoIndex + 1"
      >
        下一张
      </el-button>
      <el-button type="primary" @click="cardReade()">发 卡</el-button>
    </div>
  </div>
</template>

<script>
import { listEmitCard, addEmitCard, writeIcCard } from "@/api/vip/base/emitCard.js";
import { vipParamListAPI } from "@/api/shop/setup/posParam/vipParam";
export default {
  name: "IDGrant",
  model: { prop: "formData", event: "Device" },
  props: {
    formData: {
      type: Object,
    },
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      vipCardNoIndex: 0, //会员资料列表下标
      idForm: {
        idPhysicalNo: "", //  读卡器端口
        idPhysicalNoConfirm: "", //波特率
      },
    };
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.idPhysicalNo.focus();
  },
  methods: {
    //读卡
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
        customClass: "topLoading",
      });
    },
    closeLoading() {
      try {
        this.loading.close();
      } catch (error) {}
    },
    async cardReade() {
      try {
        if (!this.idForm.idPhysicalNo) {
          return this.$message.error("请输入实体卡号");
        }
        if (!this.idForm.idPhysicalNoConfirm) {
          return this.$message.error("请输入确认实体卡号");
        }
        if (this.idForm.idPhysicalNo != this.idForm.idPhysicalNoConfirm) {
          return this.$message.error("两次输入的实体卡号不一致");
        }
        this.showLoading();
        const vipInfo = this.formData.list[this.vipCardNoIndex];
        await addEmitCard({
          vipNo: vipInfo.vipNo,
          idPhysicalNo: this.idForm.idPhysicalNo,
        });
        this.formData.delTableItemNo = vipInfo.vipNo;
        try {
          if (this.formData.list.length == this.vipCardNoIndex + 1) {
            this.$emit("dialogEvent", "delTableItem");
            await this.$nextTick();
            this.$message.success(`发卡成功`);
            this.$emit("dialogEvent", "dialogClose");
          } else {
            this.closeLoading();
            this.$emit("dialogEvent", "delTableItem");
            this.$message.success(`发卡成功`);
            this.idForm = {
              idPhysicalNo: "", //  读卡器端口
              idPhysicalNoConfirm: "", //波特率
            };
            this.cardNext();
            await this.$nextTick();
            this.$refs.idPhysicalNo.focus();
          }
        } catch (error) {}
      } catch (error) {}
      this.closeLoading();
    },
    //下一张卡号、姓名
    cardNext() {
      this.vipCardNoIndex = this.vipCardNoIndex + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-info {
  display: flex;
  justify-content: space-between;
  .left-item {
    display: flex;
  }
}

::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}
::v-deep .el-card__body {
  height: 300px;
}
</style>
