<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
      <div class="table-setting">
        <el-form inline :model="form" size="mini" label-width="100px">
          <el-form-item label="左边距" style="width: 40%">
            <el-input-number
              v-model="form.paddingLeft"
              :controls="false"
              :precision="0"
              :step="1"
              :min="0"
              label="请输入左边距"
              style="text-align: left"
              size="mini"
            />
          </el-form-item>
          <el-form-item label="右边距" style="width: 40%">
            <el-input-number
              v-model="form.paddingRight"
              :controls="false"
              :precision="0"
              :step="1"
              :min="0"
              label="请输入右边距"
              style="text-align: left"
              size="mini"
            />
          </el-form-item>
          <el-form-item label="上边距" style="width: 40%">
            <el-input-number
              v-model="form.paddingTop"
              :controls="false"
              :precision="0"
              :step="1"
              :min="0"
              label="请输入上边距"
              style="text-align: left"
              size="mini"
            />
          </el-form-item>
          <el-form-item label="下边距" style="width: 40%">
            <el-input-number
              v-model="form.paddingBottom"
              :controls="false"
              :precision="0"
              :step="1"
              :min="0"
              label="请输入下边距"
              style="text-align: left"
              size="mini"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent('dialogChange')">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";

export default {
  name: "PrintTemplateSetting",
  mixins: [mixin],
  data() {
    return {
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "paperType",
        type: "local",
        label: "纸张规格",
        option: {
          label: "label",
          value: "value",
          data: [
            { value: "A4", label: "A4", width: 210, height: 297 },
            { value: "ThreeOne", label: "三联纸一等分", width: 241, height: 280 },
            { value: "ThreeTwo", label: "三联纸二等分", width: 241, height: 140 },
            { value: "ThreeThree", label: "三联纸三等分", width: 241, height: 93 },
          ],
          change: (e, obj) => {
            this.form.width = obj.width;
            this.form.height = obj.height;
          },
        },
      },
      {
        model: "width",
        type: "input",
        label: "宽度(W)",
      },
      {
        model: "height",
        type: "input",
        label: "高度(H)",
      },
      {
        label: "方向",
        model: "direction",
        type: "radio",
        optionData: [
          { value: 1, label: "纵向" },
          { value: 2, label: "横向" },
        ],
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
