<template>
  <div class="dialog-content">
    <div class="content">
      <el-cascader-panel
        v-model="form.list"
        :options="dialog.data"
        :props="{
          multiple: true,
          value: 'id',
          expandTrigger: 'hover',
        }"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { getAllRegion } from "@/api/goods/region"; // 全部省市区 树接口

export default {
  name: "O2OFreightTemp",
  mixins: [mixin],
  data() {
    return {
      nowInCity: false,
    };
  },
  methods: {
    onClick() {
      console.log("onClickonClickonClickonClick");
    },
    submit() {
      if (!this.form.list?.length) return this.$message.error("请选择配送区域");
      const keys = {
        0: "provinceId",
        1: "cityId",
        2: "areaId",
      };
      this.form.list = this.form.list.map((x) =>
        x.reduce((acc, cur, i) => ({ ...acc, [keys[i]]: cur }), {})
      );
      this.$emit("dialogEvent", "dialogChange");
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  .content {
    display: flex;
    word-break: break-all;
    justify-content: space-between;
    min-width: 600px;
    flex-wrap: wrap;
    .province {
      width: 30%;
      display: flex;
    }
    ::v-deep .el-cascader-menu__wrap.el-scrollbar__wrap {
      height: 300px;
    }
    .all-text {
      height: 33px;
      line-height: 33px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>

<style lang="scss">
.O2OFreightPoper {
  padding: 6px;
  .O2OFreightPoper-all-text-view {
    display: flex;
    align-items: center;
    .O2OFreightPoper-all-text {
      margin-left: 3px;
      height: 33px;
      line-height: 33px;
      max-width: calc(100% - 44px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
