<template>
  <div class="dialog-content">
    <div class="content">
      <TablePage v-if="step === 0" ref="tablePage" v-model="options" />
      <div v-else>
        <el-radio-group v-model="form.couponEffectMode">
          <div class="marB20">
            <el-radio :label="0">即时生效</el-radio>
            <span class="fontS14 marR10">生效后有效时长</span>
            <el-input-number
              style="width: 140px; margin-right: 4px"
              v-model="form.couponValidDays"
              :precision="0"
              :step="1"
              :min="1"
              :controls="false"
              :disabled="form.couponEffectMode != 0"
            />
            <span class="fontS14 marR20">天</span>
          </div>
          <div class="marB20">
            <el-radio :label="1">延迟生效</el-radio>
            <span class="fontS14 marR20">领取</span>
            <el-input-number
              style="width: 140px; margin-right: 4px"
              v-model="form.couponEffectDays"
              :precision="0"
              :step="1"
              :min="1"
              :controls="false"
              :disabled="form.couponEffectMode != 1"
            />
            <span class="fontS14 marR20">天后生效</span>
            <span class="fontS14 marR20">, 生效后有效时长</span>
            <el-input-number
              style="width: 140px; margin-right: 4px"
              v-model="form.couponValidDays"
              :precision="0"
              :step="1"
              :min="1"
              :controls="false"
              :disabled="form.couponEffectMode != 1"
            />
            <span class="fontS14 marR20">天</span>
            <span class="fontS14 annotateGrey marR20">
              (优惠券生效时间从当天的00:00开始)
            </span>
          </div>
          <div>
            <el-radio :label="2">固定日期生效</el-radio>
            <el-date-picker
              class="marR20"
              v-model="form.couponEffectDate"
              :disabled="form.couponEffectMode != 2"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            <span class="fontS14 marR20">, 生效后有效时长</span>
            <el-input-number
              style="width: 140px; margin-right: 4px"
              v-model="form.couponValidDays"
              :precision="0"
              :step="1"
              :min="1"
              :controls="false"
              :disabled="form.couponEffectMode != 2"
            />
            <span class="fontS14 marR20">天</span>
            <span class="fontS14 annotateGrey marR20"
              >(优惠券生效时间从当天的00:00开始)</span
            >
          </div>
        </el-radio-group>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button v-if="step === 0" type="primary" plain @click="setStep(1)">
        下一步
      </el-button>
      <el-button v-else type="primary" plain @click="setStep(-1)">上一步</el-button>
      <el-button v-if="step === 1" type="primary" @click="submit(1)"> 确 认 </el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { CouponList } from "@/api/marketing/coupon/case";
export default {
  name: "O2OLuckDrawCoupon",
  mixins: [mixin],
  components: { TablePage: () => import("@/components/tablePage/index.vue") },
  data() {
    return {
      step: 0,
      options: {
        loading: false,
        radioSelect: true,
        listNo: true,
        height: 300,
        getListApi: CouponList,
        // defaultBody: { isStop: false, isInvalid: 1 },
        defaultBody: { isStop: false, isInvalids: [0, 1] },
        check: [],
        rowKey: "couponCaseId",
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券方案编号",
            minWidth: 120,
            fixed: true,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 120,
            fixed: true,
          },
          {
            prop: "couponCaseTypeName",
            label: "优惠券类型",
            minWidth: 90,
          },
          {
            prop: "couponModeName",
            label: "优惠券形式",
            minWidth: 110,
          },
          {
            prop: "couponDesc",
            label: "优惠券内容",
            minWidth: 120,
          },

          {
            prop: "validBegTime",
            label: "生效时间",
            minWidth: 155,
          },
          {
            prop: "validEndTime",
            label: "失效时间",
            minWidth: 155,
          },
          {
            prop: "couponStatus",
            label: "优惠券状态",
            minWidth: 90,
          },
          {
            prop: "couponCount",
            label: "优惠券发放总数",
            minWidth: 120,
          },
          {
            prop: "scrapCount",
            label: "作废",
            minWidth: 80,
          },
          {
            prop: "drawCount",
            label: "已领",
            minWidth: 80,
          },
          {
            prop: "usedCount",
            label: "已核销",
            minWidth: 80,
          },
          {
            prop: "sellCount",
            label: "销售",
            minWidth: 80,
          },
          {
            prop: "sellBackCount",
            label: "销售退回",
            minWidth: 80,
          },
          {
            prop: "giveCount",
            label: "转增",
            minWidth: 80,
          },

          {
            prop: "createBy",
            label: "创建人",
            minWidth: 80,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 80,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
          },
        ],
      },
    };
  },

  methods: {
    setStep(e) {
      if (e > 0) {
        if (!this.options.radioObject) {
          return this.$message.warning("请选择优惠券方案");
        }
        const dialog = this.dialog;
        dialog.title = "设置优惠券生效方式";
        this.$emit("update:dialog", dialog);
      } else {
        const dialog = this.dialog;
        dialog.title = "选择优惠券方案";
        this.form.couponEffectMode = undefined;
        this.form.couponEffectDate = undefined;
        this.form.couponEffectDays = undefined;
        this.form.couponValidDays = undefined;
        this.$emit("update:dialog", dialog);
      }
      this.step = this.step + e;
    },
    submit() {
      if (![0, 1, 2].includes(this.form.couponEffectMode))
        return this.$message.error("请选择生效方式");
      if (
        !this.form.couponValidDays ||
        (this.form.couponEffectMode === 1
          ? !this.form.couponEffectDays
          : this.form.couponEffectMode === 2 && !this.form.couponEffectDate)
      )
        return this.$message.error("请填生效方式设置");
      this.form.largessCouponCaseId = this.options.radioObject.couponCaseId;
      this.form.couponCaseTypeName = this.options.radioObject.couponCaseTypeName;
      this.form.couponCaseName = this.options.radioObject.couponCaseName;
      this.form.parValue = this.options.radioObject.parValue;
      this.$emit("dialogEvent", this.dialog.click);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>
