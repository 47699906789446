<template>
  <!-- IC/ID发放 -->
  <div class="dialog-content" v-if="dialog.show">
    <el-form :model="cardEmitForm" label-width="98px">
      <div class="content">
        <div class="top-info">
            <div class="left">
          <div class="left-item">
            <el-form-item label="会员卡号">
              <el-input
                class="dialogInput"
                v-model="cardEmitForm.vipNo"
                autocomplete="off"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="会员姓名">
              <el-input
                class="dialogInput"
                v-model="cardEmitForm.vipName"
                autocomplete="off"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </div>
          <div class="left-item">
            <el-form-item label="实体卡号">
              <el-input
                class="dialogInput"
                v-model="cardEmitForm.icPhysicalNo"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认实体卡号">
              <el-input
                class="dialogInput"
                v-model="cardEmitForm.conIcPhysicalNo"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="right">
          <el-button size="mini" type="primary" @click="cardReade"
            >写卡</el-button
          >
          <el-button size="mini" type="success" @click="cardNext" :disabled="this.formData.list.length == (this.vipCardNoIndex + 1)"
            >下一张</el-button
          >
        </div>

        </div>
      
        <el-card class="textCard">
          <div>
            <span>{{ message }}</span>
          </div>
        </el-card>
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="$emit('dialogEvent', 'dialogClose')"
        >退出</el-button
      >
    </div>
  </div>
</template>

<script>
import { listEmitCard, addEmitCard } from '@/api/vip/base/emitCard.js'
export default {
  name: 'IDGrant',
  model: { prop: 'formData', event: 'Device' },
  props: {
    formData: {
      type: Object
    },
    dialog: {
      type: Object
    }
  },
  watch:{
    'dialog.show':{
        handler (val) {
            if(val){
                console.log(this.formData.list)
                this.cardEmitForm.vipNo = this.formData.list[0].vipNo
                this.cardVipName = this.formData.list[0].vipName
            }
        },
        immediate:true
    }
  },
  data () {
    return {
      vipCardNoIndex: 0, //会员资料列表下标
      cardEmitForm: {
        icPhysicalNo: undefined, //IC卡物理卡号
        idPhysicalNo: undefined, //ID卡物理卡号
        vipNo: undefined, //会员号
        vipName:undefined,
        conIcPhysicalNo:undefined,//确认ic卡物理卡号
      },
      message:undefined,
    }
  },
  methods: {
    //读卡
    async cardReade () {
      if (this.cardEmitForm.icPhysicalNo != this.cardEmitForm.conIcPhysicalNo) {
        return this.$message.error('两次实体卡号不一致')
      }
      try {
        await addEmitCard(this.cardEmitForm)
        this.$alert(`会员卡[${this.cardEmitForm.vipNo}]已成功发放`, {
          confirmButtonText: '确定'
        })
        this.message = `会员卡[${this.cardEmitForm.vipNo}]已成功发放`
        this.formData.delTableItemNo = this.cardEmitForm.vipNo
        this.$emit('dialogEvent','delTableItem')
      } catch (error) {
        this.$alert(`会员卡[${this.cardEmitForm.vipNo}]发放失败`, {
          confirmButtonText: '确定'
        })
        this.message = `会员卡[${this.cardEmitForm.vipNo}]发放失败`
      }
    },
    //下一张卡号、姓名
    cardNext () {
      this.vipCardNoIndex = this.vipCardNoIndex + 1
      this.cardEmitForm.vipNo = this.formData.list[this.vipCardNoIndex].vipNo
      this.cardVipName = this.formData.list[this.vipCardNoIndex].vipName
      this.cardEmitForm.icPhysicalNo = undefined
      this.cardEmitForm.conIcPhysicalNo = undefined
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.top-info{
    display: flex;
    justify-content: space-between;
    .left-item{
        display: flex;
    }
}

::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}
::v-deep .el-card__body {
  height: 300px;
}
</style>
