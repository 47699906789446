var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog.data
    ? _c("div", { staticClass: "dialog-content" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("TablePage", {
              ref: "tablePage",
              on: { handleEvent: _vm.handleEvent },
              model: {
                value: _vm.dialog.data,
                callback: function ($$v) {
                  _vm.$set(_vm.dialog, "data", $$v)
                },
                expression: "dialog.data",
              },
            }),
          ],
          1
        ),
        _vm.dialog.data.restoreListApi
          ? _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("dialogEvent", "dialogClose")
                      },
                    },
                  },
                  [_vm._v("关 闭")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.restore(_vm.dialog.click)
                      },
                    },
                  },
                  [_vm._v("还 原")]
                ),
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("dialogEvent", "dialogClose")
                      },
                    },
                  },
                  [_vm._v(" 确 认 ")]
                ),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }