<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  vipBrandSave
} from '@/api/vip/wxService/wxVipcard' //微信会员包
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "VipBand",
  mixins: [mixin],
  data() {
    return {
      requests: { add: vipBrandSave, update: vipBrandSave },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "vipBrandNo",
        type: "input",
        maxlength: 20,
        label: "会员品牌编号",
        rules: [
          {
            required: true,
            message: "请输入会员品牌编号",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "vipBrandName",
        type: "input",
        maxlength: 20,
        label: "会员品牌名称",
        rules: [
          {
            required: true,
            message: "请输入会员品牌名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "isWxVipCard",
        type: "switch",
        label: "是否微信卡包",
        open:true,
        close:false,
      }
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
