var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.options,
            rules: _vm.rules,
            "label-width": "106px",
          },
        },
        [
          _c(
            "cardTitleCom",
            { staticClass: "options", attrs: { cardTitle: "" } },
            [
              _c("template", { slot: "leftCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "leftContent x-fsa marL15" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text",
                        staticStyle: { width: "160px", "margin-right": "20px" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            "超" +
                              (_vm.billType == "190101" ? "收" : "付") +
                              "转预收金额："
                          )
                        ),
                        _c(
                          "span",
                          { staticStyle: { color: "rgb(255, 117, 0)" } },
                          [_vm._v(_vm._s(_vm.exceedAmount))]
                        ),
                      ]
                    ),
                    _c("span", { staticClass: "text" }, [_vm._v("优惠金额：")]),
                    _c("el-input", {
                      staticClass: "fl",
                      attrs: { placeholder: "请输入优惠金额" },
                      model: {
                        value: _vm.discountMoney,
                        callback: function ($$v) {
                          _vm.discountMoney = $$v
                        },
                        expression: "discountMoney",
                      },
                    }),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "fl",
                        attrs: {
                          effect: "dark",
                          content:
                            "用于记录" +
                            (_vm.billType == "190101" ? "收" : "付") +
                            "款时的优惠或折扣",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: { color: "#a4a4a4" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.options },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.$emit("dialogEvent", "dialogClose")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.subClick()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }