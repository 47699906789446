<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="80px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { addRemind, updateRemind } from "@/api/system/remind.js";
import { mixin } from "@/components/Dialog/components/mixin.js";
import { SYS_REMINDER_STATUS } from "@/utils/constant.js";
export default {
  name: "Remind",
  mixins: [mixin],
  data() {
    return {
      requests: { add: addRemind, update: updateRemind },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "sysRemindTypeId", //
        type: "local",
        label: "提醒类型",
        rules: [
          {
            required: true,
            message: "请选择提醒类型",
            trigger: ["blur", "change"],
          },
        ],
        option: {
          remote: this.getDicts,
          remoteBody: "sys_remind_type",
          label: "dictLabel",
          value: "dictValue",
          filterable: true,
        },
      },
      {
        model: "sysRemindTitle",
        type: "input",
        label: "提醒标题",
        rules: [
          {
            required: true,
            message: "请输入提醒标题",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "isStart",
        type: "switch",
        label: "启用",
        open: SYS_REMINDER_STATUS.start,
        close: SYS_REMINDER_STATUS.stop,
      },
      {
        model: "sysRemindTimeName",
        type: "input",
        label: "提醒时机",
        rules: [
          {
            required: true,
            message: "请输入提醒标题",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "sysMenuId", //
        type: "cascader",
        label: "链接菜单",
        rules: [
          {
            required: true,
            message: "请选择链接菜单",
            trigger: ["blur", "change"],
          },
        ],
        option: {
          data: this.dataObj?.menuList || [],
          label: "menuName",
          value: "menuId",
          filterable: true,
          clearable: true,
        },
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
