<template>
  <div class="dialog-content">
    <div
      class="content-one"
      v-if="curPage == 1"
      v-loading="loading"
      element-loading-text="数据导入中!请耐心等待"
    >
      <div class="boxRow1 x-bc marB10">
        <el-button size="mini" icon="el-icon-upload2" @click="clickUpload"
          >上传文件</el-button
        >
        <div class="fontS14">
          引入文件必须符合模板格式，请下载
          <el-button type="text" @click="downloadFileFn()" class="downloadBtn">
            默认模板
          </el-button>
        </div>
      </div>
      <el-upload
        class="upload-resource"
        drag
        action="#"
        :http-request="handleFile"
        :file-list="fileList"
        :before-upload="handleVerify"
        multiple
        ref="upload"
        :before-remove="handleRemove"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <!-- 错误显示 -->
    <div class="content-two" v-else>
      <el-table :data="errorTableData" height="350" border style="width: 100%">
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="错误信息" align="center">
        </el-table-column>
      </el-table>
      <!-- <el-form :model="data">
        <el-table
          :data="data.hearderList"
          style="width: 100%"
          border
          height="250"
        >
          <el-table-column prop="originalName" label="目标字段" align="center">
          </el-table-column>
          <el-table-column prop="curName" label="来源字段" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  scope.row.rules
                    ? 'hearderList.' + scope.$index + `.curName`
                    : ''
                "
                :rules="{
                  required: true,
                  message: `来源字段不能为空`,
                  trigger: ['change', 'blur']
                }"
              >
                <el-input v-model="scope.row.curName" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form> -->
    </div>
    <div slot="footer" class="dialog-footer" v-show="!loading && curPage != 2">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="handleUpload">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { mixin } from '@/components/Dialog/components/mixin.js'
import * as XLSX from 'xlsx/xlsx.mjs'
import downloadApi from '@/api/download.js'
export default {
  name: 'ImportFile', // 导入组件
  mixins: [mixin],
  data () {
    return {
      loading: false,
      //上传文件类型
      fileType: ['xls', 'xlxs'],
      uploadFile: '',
      formData: undefined,
      lastUid: null,
      fileList: [],
      curPage: 1,
      type: ['xlx', 'xlsx'],
      fileInfoList: [],
      errorTableData:[],
      downLoadUrl: process.env.VUE_APP_BASE_API+"/static/会员资料导入模板.xlsx",
    }
  },
  watch: {},
  methods: {
    //下载默认模板
    downloadFileFn () {
      console.log(this.data.type, 'data')
      let link = document.createElement('a')
      link.style.display = 'none'
      // link.href = downloadApi['vipInfo']
      link.href = downloadApi[this.data.type]
      link.download = '隼云导入示范文件.xlsx' // 设置下载的文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    //解析xlsx
    // async handleChange (ev, fileList) {
    //   if (fileList.length > 0) {
    //     this.fileList = [fileList[fileList.length - 1]]
    //   } else {
    //     this.fileList = fileList[0]
    //   }
    //   let file = ev.raw
    //   if (this.lastUid == file.uid) {
    //     returnexportApi
    //   }
    //   this.lastUid = file.uid
    //   if (file) {
    //     if (
    //       file.name.indexOf('.xls') != -1 ||
    //       file.name.indexOf('.xlsx') != -1
    //     ) {
    //       let data = await this.readFile(file)
    //       let workbook = XLSX.read(data, {
    //         type: 'binary',
    //         cellDates: true
    //       })
    //       let worksheet = workbook.Sheets[workbook.SheetNames[0]]
    //       let secondsheet = workbook.Sheets[workbook.SheetNames[1]]
    //       let resultExcel = XLSX.utils.sheet_to_json(worksheet)
    //       let tableHead = Object.keys(resultExcel[0])
    //       console.log(resultExcel, '解析后表格数据')
    //       console.log(tableHead, '解析后表格头')
    //     }
    //   }
    // },
    // readFile (file) {
    //   return new Promise(resolve => {
    //     let reader = new FileReader()
    //     reader.readAsBinaryString(file)
    //     reader.onload = ev => {
    //       resolve(ev.target.result)
    //     }
    //   })
    // },
    //模拟上传按钮点击上传
    clickUpload () {
      if (this.data.monofile && this.fileInfoList.length >= 1)
        return this.$message.warning('仅支持单文件上传')
      document.querySelector('.upload-resource .el-upload').click()
    },
    //上传前校验
    handleVerify (e) {
      if (this.data.monofile && this.fileInfoList.length >= 1) {
        this.$message.warning('仅支持单文件上传')
        return false
      }
      if (e.status === 'ready') {
        const { name: fileName } = e.raw
        if (this.type?.length) {
          const types = Array.isArray(this.type) ? this.type : [this.type]
          if (!types.includes(fileName.split('.').pop())) {
            this.$message.error(
              `上传文件失败：只能上传${
                Array.isArray(types) ? types.join('、') : ''
              }类型文件`
            )
            return false
          }
        }
        this.fileList = [e] // 只能上传一个文件
        return true
      }
    },
    //选中文件
    handleFile ({ file } = {}) {
      this.fileInfoList.push(file)
    },
    //上传地址
    async handleUpload () {
      this.$emit('update:dialog', { ...this.dialog, hideTitle: true })
      this.loading = true
      let fileHeader = []
      if (this.data.hearderList) {
        fileHeader = this.data.hearderList.map(item => ({
          key: item.originalName,
          value: item.curName
        }))
      }
      fileHeader = JSON.stringify(fileHeader)
      const formData = new FormData()
      formData.append('file', this.fileInfoList[0])
      formData.append('fileHeader', fileHeader)
      try {
        const res = await this.data.uploadApi(formData)
        const interval = setInterval(async () => {
          await this.queryIsSuccess(res.msg, interval)
        }, 3000)
        await this.queryIsSuccess(res.msg, interval)
      } catch (err) {
        this.$emit('update:dialog', { ...this.dialog, hideTitle: false })
        this.loading = false
      }
    },
    //查询是否上传成功
    async queryIsSuccess (stateKey, interval) {
      try {
        const { code, msg = '' } = await this.data.queryUploadApi(stateKey)
        if (code == 200) {
          this.$emit('update:dialog', { ...this.dialog, hideTitle: false })
          this.loading = false
          clearInterval(interval)
          this.$message.success(msg)
          this.$emit('dialogEvent', 'dialogClose')
        }
      } catch (err) {
        clearInterval(interval)
        this.$emit('update:dialog', { ...this.dialog, hideTitle: false })
        this.loading = false
        let { data } = err
        this.errorTableData = data
        this.curPage = 2
      }
    },
    //删除
    handleRemove (file) {
      this.fileInfoList = this.fileInfoList.filter(item => item.uid != file.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-one,
.content-two {
  padding: 20px;
}
.boxRow1 {
  width: 100%;

  a {
    color: #1890ff;
  }
}

::v-deep .el-icon-upload:before {
  content: '+';
}

::v-deep .el-upload-dragger .el-icon-upload {
  font-size: 57px;
}

::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: 200px;
  background-color: #f1f1f1;
}

.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-dialog .el-dialog {
  margin: 0 auto;
}
</style>
