<template>
  <div class="dialog-content" v-if="formOpt.length && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="85px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { saveTemplate, updateTemplate } from "@/api/system/system/printTemplate";
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "PrintTemplate",
  mixins: [mixin],
  data() {
    return {
      requests: { add: saveTemplate, update: updateTemplate },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    const formOpt = [
      {
        model: "printTemplateName",
        type: "input",
        label: "模板名称",
        rules: [
          {
            required: true,
            message: "请输入模板名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "printTemplateNo",
        type: "input",
        label: "模板编号",
        rules: [
          {
            required: true,
            message: "请输入模板编号",
            trigger: ["blur", "change"],
          },
        ],
      },
      ...(this.form?.lableTemplateContent
        ? [
            {
              model: "menuName",
              type: "input",
              label: "菜单",
              disabled: true,
            },
            {
              model: "tabIndex",
              type: "input",
              label: "索引",
              loadFormat: (v) => (v ? v : 0),
              disabled: true,
            },
          ]
        : [
            {
              model: "menuId",
              type: "cascader",
              label: "菜单",
              rules: [
                {
                  required: true,
                  message: "请选择菜单",
                  trigger: ["blur", "change"],
                },
              ],
              option: {
                data: this.dataObj?.menuList || [],
                label: "menuName",
                value: "menuId",
                filterable: true,
                clearable: true,
              },
            },
            {
              model: "tabIndex",
              type: "number",
              label: "索引",
              loadFormat: (v) => (v ? v : 0),
              rules: [
                {
                  required: true,
                  message: "请选择输入索引",
                  trigger: ["blur", "change"],
                },
              ],
              option: {
                controls: false,
              },
            },
          ]),
    ];
    await this.format(formOpt);
    await this.$nextTick();
    this.show = true;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog-content {
}
</style>
