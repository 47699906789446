var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _c("div", { staticClass: "img" }),
    _c("div", { staticClass: "tips" }, [
      _vm._v(
        _vm._s(_vm.billType == 190101 ? "收款" : "付款") +
          "核销总金额大于本次核销总金额，请确认处理方式。"
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini" },
            on: {
              click: function ($event) {
                return _vm.$emit("dialogEvent", "dialogClose")
              },
            },
          },
          [_vm._v("取 消")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.userClick(1)
              },
            },
          },
          [_vm._v("计入优惠金额")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.userClick(2)
              },
            },
          },
          [
            _vm._v(
              "转为预" + _vm._s(_vm.billType == 190101 ? "收" : "付") + "款"
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }