<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm
        v-model="form"
        ref="myForm"
        :options="formOpt"
        label-width="80px"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import { getAllShopInfo } from '@/api/shop/base/shopInfo'
import { getAllTenantEmployeeByName } from '@/api/system/employee'
import { save, update } from '@/api/shop/mobileQueryApp/index.js'
import { mixin } from '@/components/Dialog/components/mixin.js'
export default {
  name: 'QueryManSet',
  mixins: [mixin],
  data () {
    return {
      requests: { add: save, update: update },
      show: false,
      formOpt: []
    }
  },
  async created () {
    const formOpt = [
      {
        model: 'other1',
        type: 'local',
        label: '员工姓名',
        delete: true,
        option: {
          // disabled: this.dialog.click !== "add",
          remote: getAllTenantEmployeeByName,
          remoteBody: { shopName: '' },
          dataKey: 'data',
          label: 'employeeName',
          valueKey: 'employeeId',
          filterable: true,
          labels: [
            { title: '员工编号', label: 'employeeNo' },
            { title: '员工姓名', label: 'employeeName' }
          ],
          change: val => {
            this.form.appManNo = val.employeeNo
            this.form.appManName = val.employeeName
            this.form.appManId = val.employeeId
          }
        },
        rules: [
          {
            required: true,
            message: '请选择员工姓名',
            trigger: ['blur', 'change']
          }
        ]
      },
      {
        model: 'appManNo',
        type: 'input',
        label: '员工编号',
        disabled: true
      },
      {
        model: 'shopIds',
        type: 'local',
        label: '所属门店',
        placeholder: '全部门店',
        option: {
          remote: getAllShopInfo,
          dataKey: 'data',
          label: 'shopName',
          value: 'shopId',
          multiple: true,
          filterable: true,
          labels: [
            { title: '门店编号', label: 'shopNo' },
            { title: '门店名称', label: 'shopName' }
          ]
        }
      },
      {
        model: 'remark',
        type: 'textarea',
        label: '备注'
      }
    ]
    await this.format(formOpt)
    this.show = true
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
