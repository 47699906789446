<template>
  <div class="dialog-content" v-if="form && show">
    <div class="content">
      <myForm v-model="form" ref="myForm" :options="formOpt" label-width="110px" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="dialogEvent(dialog.click)">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { saveRoom, updateRoom, getCaseList } from "@/api/produce/decoration/index.js";
// import { saveRoom, updateRoom, getCaseList } from "@/api/produce/decoration/index.js";
import { 
  saveRoom as freshlyBakedSaveRoom,
  getCaseList as freshlyBakedGetCaseList,
  updateRoom as freshlyBakedGetUpdateRoom,
} from "@/api/freshlyBaked/base/room";
// import { saveRoom as getCaseList } from "@/api/produce/decoration/index.js";
import { mixin } from "@/components/Dialog/components/mixin.js";
import { allStoreList } from "@/api/system/store"; //仓库
// import { getAllShopInfo } from "@/api/shop/base/shopInfo";
import { treeselect } from "@/api/system/dept"; //部门
export default {
  name: "Room",
  mixins: [mixin],
  data() {
    return {
      // requests: { add: this.data.type === 'freshlyBakedRoom'? freshlyBakedSaveRoom : saveRoom, update: updateRoom },
      requests: { add: freshlyBakedSaveRoom, update: updateRoom },
      show: false,
      formOpt: [],
    };
  },
  async created() {
    console.log(this.data.type, 'type');
    if (this.data.type === 'freshlyBakedRoom') {
      this.requests = { add: freshlyBakedSaveRoom, update: freshlyBakedGetUpdateRoom }
    } else {
      this.requests = { add: saveRoom, update: updateRoom }
    }
    const formOpt = [
      {
        model: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedRoomId' : "decorationRoomId",
        type: "tree",
        label: this.data.type === 'freshlyBakedRoom'? '现烤间名称' : "裱花间名称",
        option: {
          remote: treeselect,
          label: "label",
          value: "id",
          change: (e, val) => {
            if(this.data.type === 'freshlyBakedRoom') {
              this.form.freshlyBakedRoomName = val.label;
            } else {
              this.form.decorationRoomName = val.label;
            }
          },
        },
        rules: [
          {
            required: true,
            message: this.data.type === 'freshlyBakedRoom'? '请输入现烤间间名称' : "请输入裱花间名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedRoomNo' : "decorationRoomNo",
        type: "input",
        label: this.data.type === 'freshlyBakedRoom'? '现烤间编号' : "裱花间编号",
        rules: [
          {
            required: true,
            message: this.data.type === 'freshlyBakedRoom'? '请输入现烤间编号' : "请输入裱花间编号",
            trigger: ["blur", "change"],
          },
        ],
      },
      this.$select({
        key: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedRoomStore' : "decorationRoomStore",
        getModel: true,
        option: {
          option: {
            showItem: this.dialog.showItem.map((x) => ({
              storeId: this.data.type === 'freshlyBakedRoom'? x.freshlyBakedRoomStoreId : x.decorationRoomStoreId,
              storeName: this.data.type === 'freshlyBakedRoom'? x.freshlyBakedRoomStoreName : x.decorationRoomStoreName,
            })),
          },
          rules: [
            {
              required: true,
              message: "请选择所属仓库",
              trigger: ["blur", "change"],
            },
          ],
        },
      }),
      {
        label: this.data.type === 'freshlyBakedRoom'? '现烤商品方案' : "裱花商品方案",
        type: "remote",
        model: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedCaseId' : "decorationCaseId",
        option: {
          multiple: true,
          showItem: this.data.type === 'freshlyBakedRoom'? this.form?.produceFreshlyBakedCasList : this.form?.produceDecorationCasList, // produceDecorationCasList
          remote: this.data.type === 'freshlyBakedRoom'? freshlyBakedGetCaseList : getCaseList,
          remoteBody: { isStop: this.data.type === 'freshlyBakedRoom'? 0 : true }, // TODO fuxuan true
          labels: [
            { title: "方案编号", label: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedCaseNo' : "decorationCaseNo" },
            { title: "方案名称", label: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedCaseName' : "decorationCaseName"},
          ],
          value: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedCaseId' : "decorationCaseId",
          label: this.data.type === 'freshlyBakedRoom'? 'freshlyBakedCaseName' : "decorationCaseName",
          filterable: true,
          downLoad: 10,
        },
        rules: [
          {
            required: true,
            message: this.data.type === 'freshlyBakedRoom'? '请选择现烤商品方案' : "请选择裱花商品方案",
            trigger: ["blur", "change"],
          },
        ],
      },
      {
        model: "remark",
        type: "textarea",
        label: "备注",
      },
    ];
    await this.format(formOpt);
    this.show = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
